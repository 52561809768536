const content = {
  title: 'Newsletter - Archiv',
  headvisualHeadline: 'Newsletter Archiv',
  newsletter: {
    headline: 'Der NBB-Newsletter',
    paragraph:
      'Wir möchten Sie auf dem Laufenden halten. Mit dem kostenlosen E-Mail-Newsletter der NBB jeden Monat wertvolle Tipps und wissenswerte Informationen erhalten.',
    button: {
      href: '/aktuelles/newsletter',
      text: 'Jetzt abonnieren'
    }
  }
};

export default content;
