import React from 'react';
import { graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

import MainLayout from 'src/layout/MainLayout';
import { HalvedContent, ContentBox, Button } from 'src/components';

import content from 'src/content/aktuelles/newsletter/archiv';

const NewsletterArchivPage = ({ data }) => (
  <MainLayout
    title={content.title}
    headvisual={{
      image: data.headvisual,
      headline: content.headvisualHeadline,
      position: '75'
    }}
  >
    <section>
      <p className="text-center mx-auto">
        Placeholder for Newsletter2Go archive
      </p>
    </section>
    <section>
      <HalvedContent align="center">
        <BackgroundImage fluid={data.womanStandingAndHoldingTablet.childImageSharp.fluid} />
        <ContentBox
          background="dark-blue"
          headline={content.newsletter.headline}
        >
          <p>{content.newsletter.paragraph}</p>
          <Button href={content.newsletter.button.href} variant="ghost">
            {content.newsletter.button.text}
          </Button>
        </ContentBox>
      </HalvedContent>
    </section>
  </MainLayout>
);

export default NewsletterArchivPage;

export const query = graphql`
  {
    headvisual: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "man-sitting-on-couch-with-laptop-wide.jpg" }
    ) {
      ...image
    }
    womanStandingAndHoldingTablet: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "woman-standing-and-holding-tablet.jpg" }
    ) {
      ...image
    }
  }
`;
